import React from "react";
import {useAppSelector} from "../../../components/Hooks/redux";
import PercentStatItemsList, {PercentStatItemValue} from "./PercentStatItemsList";
import {Chart} from "react-google-charts";

type GoogleChartValue = [string, string | number];

export default function StaffByMfa() {
    const dashboardData = useAppSelector((state) => state.dashboardData.data);
    const mfaValues = dashboardData?.usersByMfaCount ?? {
        totp: 0,
        sms: 0
    };
    const total = dashboardData?.totalUsers ?? mfaValues.totp + mfaValues.sms;
    const colours: string[] = ["#16d874", "#f6ae3b"];
    const options = {
        pieHole: 0.5,
        pieSliceText: "none",
        legend: {
            position: "none"
        },
        fontSize: 16,
        fontName: "Inter",
        chartArea: {width: "100%", height: "93%"},
        colors: colours
    };
    const data: GoogleChartValue[] = [
        ["MFA type", "User Count"],
        ["Authenticator", mfaValues.totp],
        ["SMS", mfaValues.sms]
    ];
    const statItems: PercentStatItemValue[] = [
        {
            name: "Authenticator",
            colour: colours[0],
            count: mfaValues.totp,
            total: total
        },
        {
            name: "SMS",
            colour: colours[1],
            count: mfaValues.sms,
            total: total
        }
    ];

    return (
        <div className="dash-widget">
            <h2>Number of staff with MFA enabled</h2>

            <div className="dash-widget-chart-container">
                <Chart
                    chartType={"PieChart"}
                    data={data}
                    options={options}
                    width="100%"
                    height="200px"
                />
            </div>
            <PercentStatItemsList items={statItems} />
        </div>
    );
}
