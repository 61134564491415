import React, {useEffect} from "react";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
import {useAppDispatch} from "../../components/Hooks/redux";
import {NavigationItem, setNavigationItems} from "../../store/navigation/navigationSlice";
import {routeNames} from "../../components/Navigation/routeNames";
import {getDashboardData} from "../../store/dashboard/thunks/thunks";
import NumberOfUsers from "./components/NumberOfUsers";
import StaffByLocation from "./components/StaffByLocation";
import StaffByGrade from "./components/StaffByGrade";
import StaffByMfa from "./components/StaffByMfa";

export default function Dashboard() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.dashboard.name,
                path: routeNames.dashboard.path
            },
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessenger.name,
                path: routeNames.staffMessenger.path
            },
            {
                name: routeNames.regionList.name,
                path: routeNames.regionList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(getDashboardData());
    }, [dispatch]);

    return (
        <>
            <TopBar titleText="Dashboard" />
            <div className="dash-container">
                <div className="dash-widgets">
                    <NumberOfUsers />

                    <div className="dash-widgets-nums-row">
                        <StaffByLocation />
                        <StaffByGrade />
                        <StaffByMfa />
                    </div>
                </div>
            </div>
        </>
    );
}
