import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {DashboardStats} from "../../api/staff/code";
import {createSlice} from "@reduxjs/toolkit";
import {getDashboardData} from "./thunks/thunks";

const initialState = createDefaultNullableStoreState<DashboardStats>(null);

const dashboardDataSlice = createSlice({
    name: "dashboardData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        createBuildHandlers(getDashboardData, builder);
    }
});

export default dashboardDataSlice.reducer;
