import React, {useEffect, useState} from "react";
import StaffDropdown from "../../../components/Dropdown/StaffDropdown";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../components/Button/DatePickerInputButton";
import moment, {Moment} from "moment";
import {RequestFilterProps} from "../../../components/Filters/helpers/filterHelpers";
import {useQuery} from "../../../components/Hooks/useQuery";
import {useHistory} from "react-router-dom";
import {pagedRequestConfig} from "../../../utils/filterUtils";
import {SmsHistoryRequest, SmsHistoryRequestRecipient} from "../../../api/staff/code";
import {useAppDispatch} from "../../../components/Hooks/redux";
import {nullifySmsLogsStore} from "../../../store/smsLogs/smsLogsSlice";

function StaffMessengerLogsFilters(props: RequestFilterProps<SmsHistoryRequest>): JSX.Element {
    const dispatch = useAppDispatch();
    const [recipient, setRecipient] = useState<string>("");
    const [sender, setSender] = useState<string>("");
    const [month, setMonth] = useState<Moment>();
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        const currentMonth = query.get("month");
        setMonth(currentMonth ? moment.unix(+currentMonth) : moment().startOf("month"));

        const currentRecipient = query.get("recipient");
        setRecipient(currentRecipient ? currentRecipient : "");

        const currentSender = query.get("sender");
        setSender(currentSender ? currentSender : "");

        return () => {
            dispatch(nullifySmsLogsStore());
        };
    }, []);

    useEffect(() => {
        if (!month) return;

        const request = buildRequest(sender, recipient, month);
        props.onRequestChanged(request);
        buildUrlHistory(request);
    }, [sender, recipient, month]);

    function buildRequest(
        incomingSender: string,
        incomingRecipient: string,
        incomingMonth: Moment
    ): SmsHistoryRequest {
        const start = incomingMonth.clone().startOf("month").unix();
        const end = incomingMonth.clone().endOf("month").unix();
        return {
            numPerPage: pagedRequestConfig.resultsPerPage,
            sender: getSmsHistoryRequestRecipient(incomingSender),
            recipient: getSmsHistoryRequestRecipient(incomingRecipient),
            startDateInclusive: start,
            endDateExclusive: end,
            pageNum: 0
        };
    }

    function buildUrlHistory(request: SmsHistoryRequest): void {
        const queryStrings: string[] = [];
        if (request.sender && request.sender.username && request.sender.username.length > 0) {
            queryStrings.push(`sender=${request.sender.username}`);
        }
        if (
            request.recipient &&
            request.recipient.username &&
            request.recipient.username.length > 0
        ) {
            queryStrings.push(`recipient=${request.recipient.username}`);
        }

        if (request.startDateInclusive) {
            queryStrings.push(`month=${request.startDateInclusive}`);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (request.pageNum >= 0) {
            queryStrings.push(`pageNum=${request.pageNum}`);
        }

        history.push({
            search: `?${queryStrings.join("&")}`
        });
    }

    function getSmsHistoryRequestRecipient(
        username?: string
    ): SmsHistoryRequestRecipient | undefined {
        if (!username) return;

        return {username};
    }

    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Month</h6>
                    <DatePicker
                        selected={month?.toDate()}
                        onChange={(newMonth) => setMonth(moment(newMonth))}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                        placeholderText="Select start date"
                    />
                </div>
                <div className="filter-item">
                    <h6>Recipient</h6>
                    <StaffDropdown
                        searchable={true}
                        changeOption={(newRecipient) => setRecipient(newRecipient.staffId)}
                        clearable={true}
                        id={recipient}
                        disabled={false}
                    />
                </div>
                <div className="filter-item">
                    <h6>Sender</h6>
                    <StaffDropdown
                        searchable={true}
                        changeOption={(newSender) => setSender(newSender.staffId)}
                        clearable={true}
                        id={sender}
                        disabled={false}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default StaffMessengerLogsFilters;
