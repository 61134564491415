import React from "react";
import {useAppSelector} from "../../../components/Hooks/redux";
import NameValuesWidget from "./NameValuesWidget";

export default function StaffByLocation() {
    const dashboardData = useAppSelector((state) => state.dashboardData.data);
    const numLocations = dashboardData?.usersByRegion?.length ?? 0;
    const rows =
        dashboardData?.usersByRegion?.map((region) => {
            return {
                key: region.region,
                name: region.region,
                value: <div className="user-count-by-location-count">{region.total.toString()}</div>
            };
        }) || [];
    rows.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
    });

    return (
        <div className="dash-widget user-count-by-location">
            <h2>Number of staff - by location</h2>
            <p className="dash-widget-subtitle">{numLocations} locations</p>

            <NameValuesWidget rows={rows} />
        </div>
    );
}
