import {useEffect} from "react";
import {routeNames} from "../../../../components/Navigation/routeNames";
import {NavigationItem, setNavigationItems} from "../../../../store/navigation/navigationSlice";
import {useAppDispatch, useAppSelector} from "../../../../components/Hooks/redux";
import {getRegionListing} from "../../../../store/regions/list/thunks/thunks";

export function useRegionListContainer() {
    const regionListStore = useAppSelector((state) => state.regionList);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.dashboard.name,
                path: routeNames.dashboard.path
            },
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessengerLogs.name,
                path: routeNames.staffMessengerLogs.path
            },
            {
                name: routeNames.regionList.name,
                path: routeNames.regionList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
        dispatch(getRegionListing());
    }, []);
    return {
        regionListStore
    };
}
