import React, {ReactNode} from "react";
import {useAppSelector} from "../../../components/Hooks/redux";
import {BsPeople, BsPerson, BsPersonGear, BsPersonLock} from "react-icons/bs";
import {StaffAccessLevel, UserCountByAccessLevel} from "../../../api/staff/code";

export default function NumberOfUsers() {
    const dashboardData = useAppSelector((state) => state.dashboardData.data);
    const staffCount = getCount(dashboardData?.usersByAccessLevel, StaffAccessLevel.Staff);
    const dutyManagerCount = getCount(
        dashboardData?.usersByAccessLevel,
        StaffAccessLevel.DutyManager
    );
    const adminCount = getCount(
        dashboardData?.usersByAccessLevel,
        StaffAccessLevel.SystemAdministrator
    );

    return (
        <div className="dash-widget">
            <h2>Number of users</h2>

            <div className="widget-user-count-row">
                <div className="widget-user-count">
                    <div className="icon">
                        <UserCountIcon className="total">
                            <BsPeople size={32} />
                        </UserCountIcon>
                    </div>
                    <div className="count">{dashboardData?.totalUsers}</div>
                    <div className="label">Total</div>
                </div>

                <div className="widget-user-count">
                    <div className="icon">
                        <UserCountIcon className="staff">
                            <BsPerson size={32} />
                        </UserCountIcon>
                    </div>
                    <div className="count">{staffCount.total}</div>
                    <div className="label">Staff</div>
                </div>

                <div className="widget-user-count">
                    <div className="icon">
                        <UserCountIcon className="duty">
                            <BsPersonGear size={32} />
                        </UserCountIcon>
                    </div>
                    <div className="count">{dutyManagerCount.total}</div>
                    <div className="label">Duty Managers</div>
                </div>

                <div className="widget-user-count">
                    <div className="icon">
                        <UserCountIcon className="admin">
                            <BsPersonLock size={32} />
                        </UserCountIcon>
                    </div>
                    <div className="count">{adminCount.total}</div>
                    <div className="label">System Administrators</div>
                </div>
            </div>
        </div>
    );
}

interface UserCountIconProps {
    className: string;
    children: ReactNode;
}

function UserCountIcon(props: UserCountIconProps) {
    const className = "widget-user-count-icon " + props.className;
    return <div className={className}>{props.children}</div>;
}

function getCount(
    data: UserCountByAccessLevel[] | null | undefined,
    accessLevel: StaffAccessLevel
): UserCountByAccessLevel {
    return data?.find((item) => item.accessLevel === accessLevel) || {accessLevel, total: 0};
}
