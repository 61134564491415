import {createBuildHandlers, createDefaultStoreState} from "../helpers/methods";
import {createSlice} from "@reduxjs/toolkit";
import {sendMassSms, SMSFormDetails} from "./thunks/thunks";
import {ReduxPayload} from "../helpers/types";
import {UserData} from "../../api/staff/code";

const initialState = createDefaultStoreState<SMSFormDetails>({
    staffMembers: [],
    filters: {},
    bodyText: ""
});

const smsSlice = createSlice({
    name: "sms",
    initialState,
    reducers: {
        nullifySMSStore(state) {
            state.data = {
                staffMembers: [],
                filters: {},
                bodyText: ""
            };
        },
        setFilters(state, action: ReduxPayload<Record<string, string>>) {
            state.data.filters = action.payload;
        },
        setSMSStaffMembers(state, action: ReduxPayload<UserData[]>) {
            state.data.staffMembers = action.payload;
        },
        setSMSBody(state, action: ReduxPayload<string>) {
            state.data.bodyText = action.payload;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(sendMassSms, builder);
    }
});

export const {nullifySMSStore, setSMSStaffMembers, setFilters, setSMSBody} = smsSlice.actions;
export default smsSlice.reducer;
