import {configureStore} from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import mcConfigSlice from "./mcConfig/mcConfigSlice";
import navigationSlice from "./navigation/navigationSlice";
import sidebarSlice from "./sidebar/sidebarSlice";
import calendarEntryListSlice from "./calendarEntryList/calendarEntryListSlice";
import regionListSlice from "./regions/list/regionListSlice";
import regionSlice from "./regions/single/regionSlice";
import venueListSlice from "./venueList/venueListSlice";
import userAbsenceYearConfigSlice from "./userAbsenceYearConfig/userAbsenceYearConfigSlice";
import userAbsenceConfigSlice from "./userAbsenceConfig/userAbsenceConfigSlice";
import staffListSlice from "./staffList/staffListSlice";
import staffSlice from "./staff/staffSlice";
import smsLogsSlice from "./smsLogs/smsLogsSlice";
import smsSlice from "./sms/smsSlice";
import userAbsenceDefaultConfigSlice from "./userAbsenceDefaultConfig/userAbsenceDefaultConfigSlice";
import dashboardDataSlice from "./dashboard/dashboardDataSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        mcConfig: mcConfigSlice,
        navigation: navigationSlice,
        dashboardData: dashboardDataSlice,
        sidebar: sidebarSlice,
        calendarEntryList: calendarEntryListSlice,
        regionList: regionListSlice,
        region: regionSlice,
        venueList: venueListSlice,
        userAbsenceYearConfig: userAbsenceYearConfigSlice,
        userAbsenceDefaultConfig: userAbsenceDefaultConfigSlice,
        userAbsenceConfig: userAbsenceConfigSlice,
        staff: staffSlice,
        staffList: staffListSlice,
        smsLogs: smsLogsSlice,
        sms: smsSlice
    }
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
