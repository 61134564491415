import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {routeNames} from "../../../../components/Navigation/routeNames";
import {NavigationItem, setNavigationItems} from "../../../../store/navigation/navigationSlice";
import {getRegion} from "../../../../store/regions/single/thunks/thunks";
import {createRegion} from "../../../../store/regions/single/regionSlice";
import {useAppDispatch, useAppSelector} from "../../../../components/Hooks/redux";

export function useEditRegionContainer() {
    const regionStore = useAppSelector((state) => state.region);
    const dispatch = useAppDispatch();
    const params: {id: string} = useParams();
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.dashboard.name,
                path: routeNames.dashboard.path
            },
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessengerLogs.name,
                path: routeNames.staffMessengerLogs.path
            },
            {
                name: routeNames.regionList.name,
                path: routeNames.regionList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        if (params.id) {
            dispatch(getRegion(+params.id));
            return;
        }

        dispatch(createRegion());
    }, []);
    return {
        regionStore
    };
}
