import React, {ReactNode} from "react";

export interface NameValueRow {
    key: string;
    name: ReactNode;
    value: ReactNode;
}

export interface NameValuesWidgetProps {
    rows: NameValueRow[];
}

export default function NameValuesWidget(props: NameValuesWidgetProps) {
    return (
        <div className="names-values-widget">
            {props.rows.map((row) => (
                <React.Fragment key={row.key}>
                    <div>{row.name}</div>
                    <div>
                        <div className="horiz-line"></div>
                    </div>
                    <div>{row.value}</div>
                </React.Fragment>
            ))}
        </div>
    );
}
