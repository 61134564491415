export const routeNames = {
    dashboard: {
        path: "/dashboard",
        name: "Dashboard"
    },
    staffDatabase: {
        path: "/staff-database",
        name: "Staff Database"
    },
    addStaff: {
        path: "/staff-database/add",
        name: "Add Staff Member"
    },
    editStaff: {
        path: "/staff-database/edit",
        name: "Edit Staff Member"
    },
    staffMessengerLogs: {
        path: "/messenger/logs",
        name: "EMS Messenger"
    },
    staffMessenger: {
        path: "/messenger/send",
        name: "EMS Messenger"
    },
    absenceConfiguration: {
        path: "/annual-leave/configure",
        name: "Annual Leave Configuration"
    },
    regionList: {
        path: "/location/list",
        name: "Locations"
    },
    regionAdd: {
        path: "/location/add",
        name: "Add Locations"
    },
    regionEdit: {
        path: "/location/edit",
        name: "Edit Locations"
    },
    //Auth pages
    login: {
        path: "/login",
        name: ""
    },
    loggedin: {
        path: "/loggedin",
        name: ""
    },
    logout: {
        path: "/logout",
        name: ""
    },
    inActive: {
        path: "/inactive",
        name: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: ""
    },
    notFound: {
        path: "/not-found",
        name: ""
    }
    // signIn: {
    //     path: "/sign-in",
    //     name: ""
    // },
    // forgotPassword: {
    //     path: "/forgot-password",
    //     name: ""
    // }
};
