import React from "react";

export interface PercentStatItemValue {
    name: string;
    colour: string;
    count: number;
    total: number;
}

export interface PercentStatItemsListProps {
    items: PercentStatItemValue[];
}

export default function PercentStatItemsList(props: PercentStatItemsListProps) {
    return (
        <div className="perc-items-list">
            {props.items.map((item) => {
                const percentage = Math.round((item.count / item.total) * 100);

                return (
                    <div key={item.name} className="perc-items-list-row">
                        <div className="perc-items-list-row-name">
                            <div
                                className="perc-items-list-row-icon"
                                style={{backgroundColor: item.colour}}
                            ></div>
                            {item.name}
                        </div>
                        <div className="perc-items-list-row-value">
                            {percentage}%{" "}
                            <span className="perc-items-list-row-desc">
                                ({item.count} / {item.total})
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
