import React from "react";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../Table/NoItemsMessage";
import {SMSFormDetails} from "../../../store/sms/thunks/thunks";
import {getClinicalGradeFriendlyText, getUiFriendlyTextWithSpaces} from "../../../utils/textUtils";
import {useAppSelector} from "../../Hooks/redux";
import {Region} from "../../../api/staff/code";

const SMSStaffListTable = (props: SMSFormDetails) => {
    const regions = useAppSelector((state) => state.regionList.data || []);

    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(props, regions)}
                headers={{
                    staffName: "Staff Name",
                    regions: "Regions",
                    clinicalGrade: "Clinical Grade",
                    tags: "Flags"
                }}
                noItemsSection={
                    <NoItemsMessage
                        message={"There are no staff members that match the filters set"}
                    />
                }
            />
        </React.Fragment>
    );
};

export default SMSStaffListTable;

interface TableRow {
    staffName: string;
    regions: string;
    clinicalGrade: string;
    tags: string;
}

function toTableRow(smsStore: SMSFormDetails, regions: Region[]): TableRow[] {
    return smsStore.staffMembers.map((item) => {
        return {
            staffName: `${item.firstName} ${item.lastName}`,
            regions: getRegionNames(item.regions, regions),
            clinicalGrade: getClinicalGradeFriendlyText(item.clinicalGrade),
            tags: getTagNames(item.flags)
        };
    });
}

function getRegionNames(regionIds: number[] | null | undefined, regions: Region[]): string {
    if (!regionIds || regionIds.length === 0) return "";

    const regionNames: string[] = [];

    for (const regionId of regionIds) {
        const region = regions.find((thisRegion) => thisRegion.id === regionId);
        if (region) regionNames.push(region.name);
    }

    return regionNames.join(", ");
}

function getTagNames(tags: string[] | null | undefined): string {
    if (!tags || tags.length === 0) return "";

    return tags.map((item) => getUiFriendlyTextWithSpaces(item.toLowerCase())).join(", ");
}
