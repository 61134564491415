import React from "react";
import {useAppSelector} from "../../../components/Hooks/redux";
import NameValuesWidget from "./NameValuesWidget";
import paramedics from "./images/paramedics.svg";
import technicians from "./images/technicians.svg";
import first_responders from "./images/first_responders.svg";
import eacs from "./images/eacs.svg";
import nurses from "./images/nurses.svg";
import {ClinicalGrade, UserCountByClinicalGrade} from "../../../api/staff/code";

export default function StaffByGrade() {
    const dashboardData = useAppSelector((state) => state.dashboardData.data);
    const numberOfGrades = 6;

    const rows = [
        {
            key: "Paramedic",
            name: (
                <div className="user-count-by-grade-name">
                    <img src={paramedics} alt="Line art picture of a paramedic" />
                    Paramedics
                </div>
            ),
            value: (
                <div className="user-count-by-grade-count">
                    {getCount(dashboardData?.usersByClinicalGrade, ClinicalGrade.Paramedic).total}
                </div>
            )
        },
        {
            key: "Technician",
            name: (
                <div className="user-count-by-grade-name">
                    <img src={technicians} alt="Line art picture of a technician" />
                    Technicians
                </div>
            ),
            value: (
                <div className="user-count-by-grade-count">
                    {getCount(dashboardData?.usersByClinicalGrade, ClinicalGrade.Technician).total}
                </div>
            )
        },
        {
            key: "FirstResponder",
            name: (
                <div className="user-count-by-grade-name">
                    <img src={first_responders} alt="Line art picture of a first responder" />
                    First Responders
                </div>
            ),
            value: (
                <div className="user-count-by-grade-count">
                    {
                        getCount(dashboardData?.usersByClinicalGrade, ClinicalGrade.FirstResponder)
                            .total
                    }
                </div>
            )
        },
        {
            key: "EAC",
            name: (
                <div className="user-count-by-grade-name">
                    <img src={nurses} alt="Line art picture of an EAC" />
                    EACS
                </div>
            ),
            value: (
                <div className="user-count-by-grade-count">
                    {getCount(dashboardData?.usersByClinicalGrade, ClinicalGrade.EAC).total}
                </div>
            )
        },
        {
            key: "Nurse",
            name: (
                <div className="user-count-by-grade-name">
                    <img src={eacs} alt="Line art picture of a nurse" />
                    Nurses
                </div>
            ),
            value: (
                <div className="user-count-by-grade-count">
                    {getCount(dashboardData?.usersByClinicalGrade, ClinicalGrade.Nurse).total}
                </div>
            )
        },
        {
            key: "Non",
            name: "Not assigned",
            value: (
                <div className="user-count-by-grade-count">
                    {getCount(dashboardData?.usersByClinicalGrade, ClinicalGrade.None).total}
                </div>
            )
        }
    ];

    return (
        <div className="dash-widget user-count-by-grade">
            <h2>Number of staff - by clinical grade</h2>
            <p className="dash-widget-subtitle">{numberOfGrades} clinical grades</p>

            <NameValuesWidget rows={rows} />
        </div>
    );
}

function getCount(
    data: UserCountByClinicalGrade[] | null | undefined,
    grade: ClinicalGrade
): UserCountByClinicalGrade {
    return data?.find((item) => item.clinicalGrade === grade) || {clinicalGrade: grade, total: 0};
}
