import React, {useState} from "react";
import {formatUnixToDDMMYYYYHHmmss} from "../../../utils/momentUtils";
import {PulseTableWithServerPagination} from "pulse_table";
import {pagedRequestConfig} from "../../../utils/filterUtils";
import NoItemsMessage from "../../../components/Table/NoItemsMessage";
import FilterContainer from "../../../components/Filters/FilterContainer";
import StaffMessengerLogsFilters from "./StaffMessengerLogsFilters";
import {
    capitalizeFirstLetter,
    getClinicalGradeFriendlyText,
    getUiFriendlyText
} from "../../../utils/textUtils";
import StaffMessengerLogsActions from "./StaffMessengerLogsActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../components/Navigation/routeNames";
import {
    ClinicalGrade,
    Region,
    SmsHistory,
    SmsHistoryRequest,
    SmsHistoryResponse,
    UserData
} from "../../../api/staff/code";
import {useAppDispatch, useAppSelector} from "../../../components/Hooks/redux";
import {getSmsLogsList} from "../../../store/smsLogs/thunks/thunks";

function StaffMessengerLogsTable(props: SmsHistoryResponse): JSX.Element {
    const staffList = useAppSelector((state) => state.staffList.data || []);
    const regions = useAppSelector((state) => state.regionList.data || []);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [smsLogsRequest, setSmsLogsRequest] = useState<SmsHistoryRequest>();

    function onRequestChanged(request: SmsHistoryRequest) {
        //Ensures service isn't hammered with requests because of state updating
        if (JSON.stringify(request) === JSON.stringify(smsLogsRequest)) return;

        fetchPagedSmsLogs(request);
        setSmsLogsRequest(request);
    }

    // Fetches Audits
    function fetchPagedSmsLogs(request: SmsHistoryRequest) {
        dispatch(getSmsLogsList(request));
    }

    function createNewSms() {
        history.push(routeNames.staffMessenger.path);
    }

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <StaffMessengerLogsFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <div className="row-modifier">
                <div className="row mt-5 ml-0 mr-0">
                    <div className="col d-flex justify-content-end pr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Create New SMS Message"}
                            onClick={createNewSms}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </div>
                </div>
            </div>
            <PulseTableWithServerPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                totalResults={props.totalEntries}
                onPageChanged={(newPageNum) => {
                    if (!smsLogsRequest) return;
                    fetchPagedSmsLogs({
                        ...smsLogsRequest,
                        pageNum: newPageNum
                    });
                }}
                customRenderers={{
                    metaData: (item: StaffMessengerLogsTableRow) => item.metaData,
                    actions: (item: StaffMessengerLogsTableRow) => (
                        <StaffMessengerLogsActions {...item} />
                    )
                }}
                items={toTableRow(props, staffList, regions)}
                headers={{
                    date: "Date",
                    message: "Message",
                    sender: "Sender",
                    metaData: "Filters",
                    actions: "Actions"
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no logs matching these filters"} />
                }
            />
        </React.Fragment>
    );
}

export default StaffMessengerLogsTable;

export interface StaffMessengerLogsTableRow {
    date: string;
    message: string;
    sender: string;
    metaData: React.ReactNode;
    actions: SmsHistory;
}

function toTableRow(
    resp: SmsHistoryResponse,
    staffList: UserData[],
    regions: Region[] | null | undefined
): StaffMessengerLogsTableRow[] {
    return resp.entries.map((item) => {
        return {
            date: formatUnixToDDMMYYYYHHmmss(item.date),
            message: item.message,
            sender: getSender(item.username, staffList),
            metaData: getMetaData(item.metadata, regions),
            actions: item
        };
    });
}

function getSender(senderUsername: string, staffList: UserData[]): string {
    const sender = staffList.find((item) => item.username === senderUsername);

    if (!sender) return "Deleted User";

    return getFullName(sender);
}

function getFullName({firstName, lastName}: UserData): string {
    return `${firstName} ${lastName}`;
}

function getMetaData(
    metaData: Record<string, string>,
    regions: Region[] | null | undefined
): React.ReactNode {
    return (
        <React.Fragment>
            {Object.entries(metaData).map(([key, value]) => {
                switch (key) {
                    case "regionIds":
                        return (
                            <div key="regions">
                                <span className="filter-name">Regions:</span>{" "}
                                {processRegionIds(value, regions)}
                            </div>
                        );
                    case "tags":
                        return (
                            <div key="tags">
                                <span className="filter-name">Flags:</span> {processTags(value)}
                            </div>
                        );
                    case "grades":
                        return (
                            <div key="grades">
                                <span className="filter-name">Grades:</span> {processGrades(value)}
                            </div>
                        );
                    default:
                        return (
                            <div key={key}>
                                <span className="filter-name">Old filter:</span>{" "}
                                {capitalizeFirstLetter(key)}
                            </div>
                        );
                }
            })}
        </React.Fragment>
    );
}

function processRegionIds(value: string, regions: Region[] | null | undefined): string {
    if (!regions || regions.length === 0) return "Unknown regions";
    const ids = value.split(",").map((item) => +item);
    const regionNames: string[] = [];

    for (const id of ids) {
        const region = regions.find((item) => item.id === id);
        if (region) regionNames.push(region.name);
        else regionNames.push("Unknown region");
    }

    return regionNames.join(", ");
}

function processTags(value: string): string {
    const tags = value.split(",");
    return tags.map((item) => getUiFriendlyText(item.toLowerCase())).join(", ");
}

function processGrades(value: string): string {
    const grades = value.split(",");
    return grades.map((item) => getClinicalGradeFriendlyText(item as ClinicalGrade)).join(", ");
}
