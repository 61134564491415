import React from "react";
import {StaffAccessLevel} from "../../api/staff/code";
import Row from "../Grid/Row";
import Col from "../Grid/Col";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import AccessDetailDropdown from "../Dropdown/AccessDetailDropdown";
import GenericTypeDropdown from "../Dropdown/GenericTypeDropdown";
import {OptionsFromType} from "../Dropdown/Helpers/dropdownUtils";
import {getUiFriendlyText} from "../../utils/textUtils";
import DeleteAccessDetail from "./DeleteAccessDetail";
import {StaffFormProps} from "../StaffForm/StaffForm";
import {getStaffAccessLevelFromString} from "../../utils/enumUtils";
import {deepCopy} from "../../utils/sortingUtils";

function AccessDetail(props: StaffFormProps) {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <label className="field-label">Access Detail</label>
                </Col>
                <Col className="d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        roundedCorner
                        innerValue={"Add Access Detail"}
                        onClick={() => {
                            const availableSubsystems = Object.keys(accessDetailUiFriendlyNames);
                            const assignedAccessDetails = Object.keys(props.user.accessDetail!);
                            //Look at what has been assigned, remove it
                            for (const assigned of assignedAccessDetails) {
                                const index = availableSubsystems.findIndex(
                                    (it) => it === assigned
                                );

                                if (index > -1) availableSubsystems.splice(index, 1);
                            }

                            const {user, onChange} = props;
                            onChange({
                                ...user,
                                accessDetail: {
                                    ...user.accessDetail,
                                    [availableSubsystems[0]]: user.accessLevel
                                }
                            });
                        }}
                        colour={ButtonColourOptions.Default}
                    />
                </Col>
            </Row>
            {Object.keys(props.user.accessDetail!).length > 0 && (
                <div className="access-control">
                    <div className="pulse-table-wrapper">
                        <table className="pulse-table fadeIn">
                            <thead>
                                <tr className="staff-access-detail-header">
                                    <th>System</th>
                                    <th>Access</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(props.user.accessDetail!).map((accessDetail) => {
                                    return (
                                        <tr key={accessDetail} className="staff-access-detail-row">
                                            <td>
                                                <AccessDetailDropdown
                                                    enumOptions={accessDetailUiFriendlyNames}
                                                    onChange={(newOption) => {
                                                        const copy = deepCopy(
                                                            props.user.accessDetail
                                                        );
                                                        delete Object.assign(copy!, {
                                                            [newOption!.value]: copy![accessDetail]
                                                        })[accessDetail];

                                                        const {user, onChange} = props;
                                                        onChange({
                                                            ...user,
                                                            accessDetail: copy
                                                        });
                                                    }}
                                                    id={accessDetail}
                                                    assignedSystems={Object.keys(
                                                        props.user.accessDetail!
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <GenericTypeDropdown
                                                    enumOptions={StaffAccessLevel}
                                                    splitByCapitalLetter={true}
                                                    getOptionsFrom={OptionsFromType.Value}
                                                    clearable={false}
                                                    searchable={false}
                                                    name={`${accessDetail}-access-level`}
                                                    changeOption={(option) => {
                                                        if (!option) return;
                                                        const {user, onChange} = props;
                                                        onChange({
                                                            ...user,
                                                            accessDetail: {
                                                                ...user.accessDetail,
                                                                [accessDetail]:
                                                                    getStaffAccessLevelFromString(
                                                                        option.toString()
                                                                    )
                                                            }
                                                        });
                                                    }}
                                                    id={props.user.accessDetail![accessDetail]}
                                                    disabled={false}
                                                    searchWithDecapitalisedFirstLetter={false}
                                                />
                                            </td>
                                            <td>
                                                <div className="row ml-0 ml-0">
                                                    <DeleteAccessDetail
                                                        accessDetailKey={accessDetail}
                                                        message={`Are you sure you want to delete ${getUiFriendlyText(
                                                            props.user.accessDetail![accessDetail]
                                                        )} access for this user?`}
                                                        user={props.user}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <div className="field-error-message-placeholder" />
        </React.Fragment>
    );
}

export default AccessDetail;

const accessDetailUiFriendlyNames = {
    staffmanagement: "Staff Management / Leave Management",
    grs: "Group Rostering System",
    dashboard: "EMS Portal",
    xd2: "X Drive",
    fms: "Fleet Management",
    oplog: "Op Logs",
    prf: "Patient Report Forms",
    cwa: "Clinical Waste Audit",
    mm: "Medicine Management",
    ppb: "My Documents",
    im: "Incidents",
    rm: "Risk Management"
};

interface TableRow {
    moduleName: string;
    accessLevel: StaffAccessLevel;
    actions: string;
}
