import React from "react";
import Icon, {IconType} from "../../../components/Icon/Icon";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {DebounceInput} from "react-debounce-input";
import CharacterLimitWarning from "../../../components/Form/CharacterLimitWarning";
import {maxTagLength} from "../../../components/SMS/Helpers/smsHelpers";
import Row from "../../../components/Grid/Row";
import Col from "../../../components/Grid/Col";
import {setExistingStaffMember} from "../../../store/staff/staffSlice";
import {UserData} from "../../../api/staff/code";
import {useAppDispatch} from "../../../components/Hooks/redux";

const UserFlag = (props: UserFlagProps) => {
    const {isShown, toggle} = useModal();
    const dispatch = useAppDispatch();

    const onDeleteFlag = () => {
        const {user, index} = props;
        if (!user.flags) return;

        const userCopy = {...user};
        userCopy.flags = user.flags.toSpliced(index, 1);
        dispatch(setExistingStaffMember(userCopy));

        toggle();
    };

    const onFlagUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {user, index} = props;
        if (!user.flags) return;
        const flags = [...user.flags];

        flags[index] = event.target.value.toUpperCase();

        dispatch(
            setExistingStaffMember({
                ...user,
                flags
            })
        );
    };

    return (
        <React.Fragment>
            <Row className="pl-4 mb-3">
                <div className="d-inline-block pr-0 mt-2">
                    <label className="flag-label">Tag {props.index + 1}</label>
                </div>
                <Col>
                    <DebounceInput
                        debounceTimeout={1}
                        className="input-fields"
                        value={props.flag}
                        onChange={onFlagUpdated}
                    />
                    <CharacterLimitWarning characterLimit={maxTagLength} textValue={props.flag} />
                </Col>
                <Col className="mt-2">
                    <div className="icon-tooltip-wrapper-md mr-3">
                        <Tooltip tooltipText={"Delete Tag"} size={"md"} place={"left"}>
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Bin}
                                size={"Medium"}
                                className="icon-dark ml-2"
                                onClick={toggle}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Delete Tag"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">Are you sure you want to delete this tag?</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={onDeleteFlag}
                                colour={ButtonColourOptions.Yellow}
                                className="mr-3"
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default UserFlag;

interface UserFlagProps {
    user: UserData;
    flag: string;
    index: number;
}
