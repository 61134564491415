import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../Store";
import {BulkSMSRequest, UserData} from "../../../api/staff/code";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";

const sendMassSms = createAsyncThunk("sms/send", async (smsDetails: SMSFormDetails) => {
    const loggedInUser = store.getState().auth.data;
    if (!loggedInUser) return;
    const bulkSmsRequest = getBulkSmsRequest(smsDetails, loggedInUser);

    return await handleServiceCall(
        StaffManagementService.contactApi.bulkSms(bulkSmsRequest),
        "POST"
    );
});

export {sendMassSms};

function getBulkSmsRequest(
    {bodyText, staffMembers, filters}: SMSFormDetails,
    {username}: UserData
): BulkSMSRequest {
    return {
        staffIds: staffMembers.map((item: UserData) => item.username),
        message: bodyText,
        senderUsername: username,
        metadata: filters
    };
}

export interface SMSFormDetails {
    staffMembers: UserData[];
    filters: Record<string, string>;
    bodyText: string;
}
