import React, {useEffect, useState} from "react";
import {DDProps} from "../../Dropdown/Helpers/dropdownUtils";
import Select, {MultiValue} from "react-select";
import {useAppDispatch, useAppSelector} from "../../Hooks/redux";
import {ClinicalGrade, UserData} from "../../../api/staff/code";
import {getClinicalGradeFriendlyText, getUiFriendlyTextWithSpaces} from "../../../utils/textUtils";
import {setFilters, setSMSStaffMembers} from "../../../store/sms/smsSlice";

export default function SMSFilters() {
    const staffList = useAppSelector((state) => state.staffList.data || []);
    const regions = useAppSelector((state) => state.regionList.data || []);
    const dispatch = useAppDispatch();
    const tags = getUniqueTags(staffList);
    const [selectedRegions, setSelectedRegions] = useState<MultiValue<DDProps>>([]);
    const [selectedTags, setSelectedTags] = useState<MultiValue<DDProps>>([]);
    const [selectedGrades, setSelectedGrades] = useState<MultiValue<DDProps>>([]);

    const regionOptions: DDProps[] = regions.map((region) => {
        return {
            label: region.name,
            value: region.id
        };
    });

    const tagOptions: DDProps[] = tags
        .filter((tag) => tag.length > 0)
        .map((tag) => {
            return {
                label: getUiFriendlyTextWithSpaces(tag.toLowerCase()),
                value: tag
            };
        });

    const clinicalGradeOptions: DDProps[] = Object.values(ClinicalGrade).map((grade) => {
        return {
            label: getClinicalGradeFriendlyText(grade),
            value: grade
        };
    });

    function onRegionSelected(newSelection: MultiValue<DDProps>) {
        setSelectedRegions(newSelection);
    }

    function onTagSelected(newSelection: MultiValue<DDProps>) {
        setSelectedTags(newSelection);
    }

    function onGradeSelected(newSelection: MultiValue<DDProps>) {
        setSelectedGrades(newSelection);
    }

    useEffect(() => {
        const regionIds = getAllowedValues(
            selectedRegions,
            (region) => region.value
        ) as Set<number>;
        const allowedTags = getAllowedValues(selectedTags, (tag) => tag.value) as Set<string>;
        const clinicalGrades = getAllowedValues(
            selectedGrades,
            (grade) => grade.value
        ) as Set<string>;
        const outputStaff: UserData[] = [];

        // If no selections made, no staff since we don't want a global send
        if (regionIds.size === 0 && allowedTags.size === 0 && clinicalGrades.size === 0) {
            dispatch(setFilters({}));
            dispatch(setSMSStaffMembers([]));
            return;
        }

        for (const staffMember of staffList) {
            if (regionIds.size > 0 && !setHasOneOf(regionIds, staffMember.regions)) continue;
            if (allowedTags.size > 0 && !setHasOneOf(allowedTags, staffMember.flags)) continue;
            if (clinicalGrades.size > 0 && !clinicalGrades.has(staffMember.clinicalGrade)) continue;

            outputStaff.push(staffMember);
        }

        dispatch(setFilters(getMetaData(regionIds, allowedTags, clinicalGrades)));
        dispatch(setSMSStaffMembers(outputStaff));
    }, [selectedRegions, selectedTags, selectedGrades, staffList, dispatch]);

    return (
        <div>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Locations</h6>
                    <Select
                        classNamePrefix="select-options"
                        options={regionOptions}
                        onChange={onRegionSelected}
                        isSearchable={false}
                        value={selectedRegions}
                        isMulti={true}
                        noOptionsMessage={() => "No Regions Found"}
                    />
                </div>

                <div className="filter-item">
                    <h6>Staff Database Tags</h6>
                    <Select
                        classNamePrefix="select-options"
                        options={tagOptions}
                        onChange={onTagSelected}
                        isSearchable={false}
                        value={selectedTags}
                        isMulti={true}
                        noOptionsMessage={() => "No Tags Found"}
                    />
                </div>

                <div className="filter-item">
                    <h6>Clinical Grades</h6>
                    <Select
                        classNamePrefix="select-options"
                        options={clinicalGradeOptions}
                        onChange={onGradeSelected}
                        isSearchable={false}
                        value={selectedGrades}
                        isMulti={true}
                        noOptionsMessage={() => "No Tags Found"}
                    />
                </div>
            </div>
        </div>
    );
}

function getMetaData(
    regionIds: Set<number>,
    tags: Set<string>,
    grades: Set<string>
): Record<string, string> {
    return {
        regionIds: Array.from(regionIds).join(","),
        tags: Array.from(tags).join(","),
        grades: Array.from(grades).join(",")
    };
}

function setHasOneOf<T>(set: Set<T>, values: T[] | undefined | null): boolean {
    if (!values || values.length === 0) return false;

    for (const value of values) {
        if (set.has(value)) return true;
    }

    return false;
}

function getAllowedValues<T>(
    values: MultiValue<DDProps>,
    transform: (value: DDProps) => T
): Set<T> {
    const output = new Set<T>();

    for (const value of values) output.add(transform(value));

    return output;
}

function getUniqueTags(staff: UserData[]): string[] {
    if (staff.length === 0) return [];
    const tags = new Set<string>();

    for (const user of staff) {
        const userTags = user.flags;
        if (!userTags) continue;

        for (const tag of userTags) {
            tags.add(tag);
        }
    }

    return Array.from(tags);
}
