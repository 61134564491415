import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {CustomLoadingWheel} from "react-state-helpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {routeNames} from "../../../components/Navigation/routeNames";
import useMcConfig from "../../../components/Hooks/useMcConfig";
import {getConfig} from "../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../utils/userDataUtils";
import {useAppDispatch, useAppSelector} from "../../../components/Hooks/redux";
import {handleUpdatedCredentials} from "../../../store/auth/auth";
import {setNavigationItems} from "../../../store/navigation/navigationSlice";
import {StaffAccessLevel} from "../../../api/staff/code";
import {login} from "../../../store/auth/thunks/thunks";

const Login = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const userStore = useAppSelector((state) => state.auth);
    const currentRoute = localStorage.getItem("current_route");
    const {config} = useMcConfig();
    useEffect(() => {
        dispatch(setNavigationItems([]));
        (async function validateSession() {
            const creds = await handleUpdatedCredentials();

            //If there is no current session, force the user to login.
            if (!creds) {
                loginToSystem();
                return;
            }

            const user = userStore.data;
            //User doesn't exist, force the user to login.
            if (!user) {
                loginToSystem();
                return;
            }

            // //User doesn't exist, force the user to login.
            if (!user) {
                loginToSystem();
                return;
            }

            //Acts like a redirect if they were logged out due to inactivity.
            if (currentRoute) {
                history.push(currentRoute);
                return;
            }
            const mcConfig = await getConfig();
            const accessLevel = getUserAccessLevel(user, mcConfig);

            switch (accessLevel) {
                case StaffAccessLevel.SystemAdministrator:
                    history.push(routeNames.dashboard.path);
                    break;

                case StaffAccessLevel.DutyManager:
                case StaffAccessLevel.Staff:
                    history.replace(routeNames.notAuthorised.path);
                    break;
            }
        })();
    }, []);

    const loginToSystem = () => {
        dispatch(login());
    };

    return (
        <div className="page background-stars">
            <h3 className="text-center title mt-5 pt-5 header-font">
                Loading {config.systemDetails.defaultName}...
            </h3>
            <CustomLoadingWheel />
            <div className="mt-5 text-center">
                <strong className="header-font">Checking Your Credentials</strong>
            </div>
            <p className="mt-4 text-center body-font">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Please wait while your credentials are checked. If you aren't redirected promptly,{" "}
                <br></br>
                click the button below <br></br>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue="Click here"
                    className="mt-4"
                    onClick={loginToSystem}
                    colour={ButtonColourOptions.Yellow}
                />
            </p>
        </div>
    );
};

export default Login;
